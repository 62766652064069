.startup-projects-card {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 200px));
  gap: 1rem 1rem;
}

/*.startup-projects-container {*/
/*  !*display: flex;*!*/
/*  !*width: 90%;*!*/
/*  !*padding: 20px 10px;*!*/
/*  !*margin: 0px auto;*!*/
/*  !*margin-top: 2rem;*!*/
/*}*/

/*.startup-projects-container > * {*/
/*  flex: 1;*/
/*}*/

.startup-project-image > img {
  max-width: 200px;
  height: 200px;
}

.project-card-head {
  color: rgb(36, 41, 46);
  font-size: 25px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin: 0px;
}

.startup-project-text {
  display: flex;
  justify-content: space-between;
}

.project-card-description {
  color: rgb(88, 96, 105);
}
.pointer {
  cursor: pointer;
}

@media (max-width: 768px) {
  .startup-project-image {
    display: none;
  }
  .project-subtitle{
    font-size: 16px;
    text-align: center;
  }
}
